import React from "react"
import styles from "../css/footer.module.css"
import links from "../constants/links"
import socialIcons from "../constants/social-icons"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Footer = () => {
  return (
    <footer className={styles.footer}>
      {/* <div className={styles.links}>
        {links.map((item, index) => {
          return (
            <AniLink fade key={index} to={item.path}>
              {item.text}
            </AniLink>
          )
        })}
      </div> */}
      <div className={styles.links}>
        <AniLink swipe to="/">Home</AniLink>
        <AniLink fade to="/vision">Vision</AniLink>
        <AniLink fade to="/information">Info</AniLink>
        <AniLink fade to="/prayer">Prayer</AniLink>
        <AniLink paintDrip to="/contact">Contact</AniLink>
      </div>
      <div className={styles.contact}>
        2200 S. Main St. Suite 212, Lombard, IL 60148
      </div>
      <div className={styles.contact}>
        Office (630) 953-2018&nbsp;Mobile (708)268-6835&nbsp;Fax (630)708-6077
      </div>

      <div className={styles.icons}>
        {socialIcons.map((item, index) => {
          return (
            <a
              key={index}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.icon}
            </a>
          )
        })}
      </div>
      <div className={styles.copyright}>
        copyright &copy; serenity one {new Date().getFullYear()} all rights
        reserved
      </div>
    </footer>
  )
}

export default Footer
