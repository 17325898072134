import React from "react"
import Link from "gatsby-link"
import AniLink from "gatsby-plugin-transition-link/AniLink"
// import AniLink from "gatsby-plugin-transition-link/AniLink"
// import { graphql } from "gatsby"
import "../css/header.css"
// import logo from "../images/serenityone.png"
// import StripeCheckout from 'react-stripe-checkout' #2

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasScrolled: false,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 50) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  // handlePurchase = (token) => {
  //   const amount = 5000
  //   const description = "My awesome product"

  //   const bodyObject = {
  //     tokenId: token.id,
  //     email: token.email,
  //     name: token.name,
  //     description,
  //     amount
  //   }

  //   fetch('http://localhost:9000/stripe-charge', {
  //     method: 'POST',
  //     body: JSON.stringify(bodyObject)
  //   })
  // }

  render() {
    return (
      <div
        className={this.state.hasScrolled ? "Header HeaderScrolled" : "Header"}
      >
        <div className="HeaderGroup">
          <AniLink swipe to="/">
            <div className="SerenityLogo">
              <div className="image" />
            </div>
          </AniLink>
          <AniLink swipe to="/">Home</AniLink>
          <AniLink fade to="/vision">Vision</AniLink>
          <AniLink fade to="/information">Info</AniLink>
          <AniLink fade to="/prayer">Prayer</AniLink>
          <AniLink paintDrip to="/contact">Contact</AniLink>

          {/* <StripeCheckout
            amount={ 5000 }
            image="https://cl.ly/0K2f1V3K3h0D/download/Logo.jpg"
            token={this.handlePurchase}
            stripeKey={'pk_test_4VuxMZhOiYXJlElaTf3qjAXh'}
            >
            <button>Buy</button> }
          </StripeCheckout> */}
        </div>
      </div>
    )
  }
}

export default Header
