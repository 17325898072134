import React from "react"
import Layout from "../components/Layout"
// import Banner from "../components/Banner"
import StyledHero from "../components/StyledHero"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import "../css/vision.css"
export default ({ data }) => (
  <Layout>
    <SEO title="Vision" />
    <StyledHero home="true" img={data.images1.childImageSharp.fluid}>
      {/* <div className="Mission"> */}
      <div className="MissionStatement">
        <span style={{ color: "#FAF35E" }}>Mission Statement</span>
      </div>
      {/* <div className="MissionDetails"> */}
      <div className="Mission1">
        We &nbsp;&nbsp;&nbsp; provide &nbsp;&nbsp;&nbsp; holistic
        &nbsp;&nbsp;&nbsp; care &nbsp;&nbsp;&nbsp; for &nbsp;&nbsp;&nbsp;
        patient&nbsp;&nbsp;&nbsp;&nbsp; and
      </div>
      <div className="Mission2">
        family, &nbsp;&nbsp;so&nbsp; they &nbsp;can journey through &nbsp;the
        &nbsp;&nbsp;process
      </div>
      <div className="Mission3">
        of&nbsp;&nbsp;&nbsp; losing &nbsp;a&nbsp; loved &nbsp;one &nbsp;in
        &nbsp;Comfort,&nbsp;&nbsp; Dignity &nbsp;&nbsp;&nbsp;and
      </div>
      <div className="Mission4">Serenity.</div>
      {/* </div> */}
      {/* </div> */}

      {/* <div className="Vision"> */}
      <div className="VisionStatement">
        <span style={{ color: "#FAF35E" }}>Vision Statement</span>
      </div>
      {/* <div className="VisionDetails"> */}
      <div className="Vision1">
        We &nbsp;&nbsp;&nbsp;are&nbsp;&nbsp; a &nbsp;&nbsp;&nbsp;hospice
        &nbsp;&nbsp;&nbsp;company &nbsp;&nbsp;&nbsp;that
        &nbsp;&nbsp;&nbsp;constantly
      </div>
      <div className="Vision2">
        strive &nbsp;&nbsp;&nbsp;&nbsp;for &nbsp;excellence &nbsp;&nbsp;in
        caring &nbsp;for&nbsp;&nbsp; our&nbsp;&nbsp;&nbsp; patient
      </div>
      <div className="Vision3">
        and &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;family &nbsp;&nbsp;&nbsp;&nbsp;and
        &nbsp;&nbsp;&nbsp;&nbsp; our
        &nbsp;&nbsp;&nbsp;people.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We
        &nbsp;&nbsp;are &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;an
      </div>
      <div className="Vision4">
        organization&nbsp;&nbsp; that &nbsp;shares&nbsp; wealth, &nbsp;&nbsp;its
        values&nbsp; and
      </div>
      <div className="Vision5">
        supports&nbsp;&nbsp;&nbsp; our &nbsp;&nbsp;&nbsp;people’s
        &nbsp;&nbsp;aspirations&nbsp;&nbsp; in &nbsp;&nbsp;&nbsp; life
        &nbsp;&nbsp;&nbsp;&nbsp;in
      </div>
      <div className="Vision6">
        allegiance&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; with &nbsp;&nbsp;&nbsp;the
        &nbsp;&nbsp;&nbsp;company’s
        &nbsp;&nbsp;&nbsp;values&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; and
      </div>
      <div className="Vision7">
        mission.&nbsp;&nbsp;&nbsp; We &nbsp;are &nbsp;an organization&nbsp;
        that&nbsp;&nbsp; practices
      </div>
      <div className="Vision8">
        and honors these values in all of our undertakings
      </div>
      <div className="Vision9">
        including &nbsp;&nbsp;the &nbsp;choices we have to make &nbsp;in
        &nbsp;&nbsp;&nbsp;work
      </div>
      <div className="Vision10">and in life. </div>
      {/* </div> */}
      {/* </div> */}
      <div className="CorporateValues">
        <div className="Corp1">• Integrity</div>
        <div className="Corp2">• Dignity</div>
        <div className="Corp3">• Courage</div>
        <div className="Corp4">• Compassion</div>
        <div className="Corp5">• Wisdom</div>
      </div>
    </StyledHero>
  </Layout>
)
export const query = graphql`
  query {
    images1: file(relativePath: { eq: "serenity-hero1.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
